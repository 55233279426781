import React from 'react';
import MainIcon from "../../icons/AIBrainGames.png";
import '../../css/general/LegalInfoPopup.css';

function LegalInfoPopup({ onDismiss }) {
    return (
        <div className="legal-info-popup-container" onClick={onDismiss}>
            <div className="legal-info-popup" onClick={(event) => event.stopPropagation()}>
                <button className="legal-info-close" onClick={onDismiss}>X</button>
                <img src={MainIcon} alt="AI Brain Games" className="legal-info-icon"/>
                <p className="legal-info-p-item">AI Brain Games is built around the concept of building games with new AI technology. Every game on our site leverages AI to some capacity.<br/><br/><b>Crossword
                    Architect</b>, <b>Clueless</b>, and <b>The Chain</b> all use AI to generate the entire game's content. <br/><br/><b>Waterfall</b> was
                    an idea that AI created itself that I brought to life.</p>
                <p className="legal-info-p-item">If you need anything or have questions, you can reach out to davidthurmanwork@gmail.com</p>
                <p className="legal-info-p-item" id="legal-info-disclaimer">Disclaimer: Games are generated by AI and
                    results can't be verified</p>
                <div className="legal-info-item-container">
                    <p className="legal-info-p-copyright">© 2024 AI Brain Games. All Rights Reserved.</p>
                    <p className="legal-info-item-dot">•</p>
                    <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=e385d701-3f62-40f9-b661-b485d5ca188a"
                       className="legal-info-item">Terms of Use</a>
                    <p className="legal-info-item-dot">•</p>
                    <a href="https://app.termly.io/policy-viewer/policy.html?policyUUID=50078d71-a78f-496b-a633-712d0df91b8b"
                       className="legal-info-item">Privacy Policy</a>
                </div>
            </div>
        </div>
    )
}


export default LegalInfoPopup;
