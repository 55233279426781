import {ChainWord} from "../components/chain/ChainWord";
import {ChainTile} from "../components/chain/ChainTile";

export const ChainTileStatus = {
    REVEALED: 'revealed',
    DISABLED: 'disabled',
    CORRECT: 'correct',
    GIVEN: 'given',
    HIGHLIGHTED: 'highlighted',
    INCORRECT: 'incorrect',
    VICTORY: 'victory'
}

export function createWordsFromFetchedWords(fetchedWords) {
    let words = [];
    for (let wordIndex = 0; wordIndex < fetchedWords.length; wordIndex++) {
        let word = [];
        for (let i = 0; i < fetchedWords[wordIndex].length; i++) {
            let currentVal = '';
            let status = '';
            if (i === 0 || wordIndex === 0 || wordIndex === fetchedWords.length - 1) {
                currentVal = fetchedWords[wordIndex][i];
                status = ChainTileStatus.GIVEN;
            }
            if (wordIndex === 1 && i === 1) {
                status = ChainTileStatus.HIGHLIGHTED
            }
            word.push(new ChainTile(fetchedWords[wordIndex][i], currentVal, status));
        }
        let isCompleted = (wordIndex === 0 || wordIndex === fetchedWords.length - 1);
        let isEditable = (wordIndex === 1 || wordIndex === fetchedWords.length - 2)
        words.push(new ChainWord(word, fetchedWords[wordIndex], isCompleted, isEditable));
    }
    return words
};