import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Header from './Header.js';
import './css/games/shared/GeneralScreen.css';
import './css/games/shared/Popup.css';
import ReactGA from 'react-ga4';
import LoadingV2 from "./utils/LoadingV2";
import Instructions from "./components/instructions/Instructions";
import {GlobalStateProvider, useGlobalState} from './contexts/GlobalStateContext';
import {NavigationProvider} from "./contexts/NavigationContext";
import OopsPage from "./components/shared/OopsPage";
import OnScreenKeyboard from "./components/shared/OnScreenKeyboard";
import {GenericGamePage} from "./pages/GenericGamePage";
import {Games} from "./components/shared/Constants";

function App() {
    if (process.env.REACT_APP_ENV === 'production') {
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
    }

    return (
        <Router>
            <NavigationProvider>
                <GlobalStateProvider>
                    <div className="app-container">
                        <Header />
                        <div className="game-container">
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/crosswordarchitect/:category?/:gameNumber?" element={<GenericGamePage gameType={Games.CROSSWORD} />} />
                                <Route path="/clueless/:category?/:gameNumber?" element={<GenericGamePage key='clueless' gameType={Games.CLUELESS} />} />
                                <Route path="/waterfall/:gameType?/:gameNumber?" element={<GenericGamePage key='waterfall' gameType={Games.WATERFALL} />} />
                                <Route path="/chain/:gameType?/:gameNumber?" element={<GenericGamePage key='chain' gameType={Games.CHAIN} />} />
                            </Routes>
                        </div>
                        <AlertAndLoading />
                    </div>
                </GlobalStateProvider>
            </NavigationProvider>
        </Router>
    );
}

const AlertAndLoading = () => {
    const { showLoading, showAlert, alertText, showInstructions, gameType, setShowInstructions, showOops, onOopsRetry, isMobile} = useGlobalState();

    return (
        <>
            {showLoading && <LoadingV2 />}
            {showOops && <OopsPage onRetry={onOopsRetry}/>}
            {showAlert && <div className="clipboard-alert">{alertText}</div>}
            {showInstructions && (<Instructions gameType={gameType} setShowInstructions={setShowInstructions} />)}
            {(isMobile && !showLoading) && <OnScreenKeyboard />}
        </>
    );
};

export default App;