import {Constants, Direction, Games} from "../components/shared/Constants";
import {titleCase} from "./Formatter";
import {getLetterSquareFromWord} from "../components/crossword/CrosswordHelper";

function getCrosswordShare(gameState) {
    let emojiGrid = getCrosswordEmojiDiagram(gameState)
    let shareString = "I created my own Crossword with the power of AI!\n" +
        "Category: " + titleCase(gameState.category) + "\n"
    if (!gameState.gameEnabled) {
        shareString += "My Time: " + gameState.getDisplayTime() + "\n";
    }
    if (emojiGrid) {
        shareString += emojiGrid
    }
    shareString += Constants.BASE_URL + Games.CROSSWORD.url + "/" + gameState.category + "/" + gameState.gameNumber;
    return shareString
}

function getChainShare(gameState) {
    let emojiGrid = getChainEmojiDiagram(gameState)
    let shareString = "The Chain #" + gameState.gameNumber + "\n" +
        "Time: " + gameState.getDisplayTime() + "\n" +
        "Remaining Lives: " + gameState.lives + "\n" +
        emojiGrid
    shareString += Constants.BASE_URL + Games.CHAIN.url;
    let gameType = gameState.isDaily ? "/daily/" : "/custom/"
    shareString += gameType + gameState.gameNumber
    return shareString
}

function getWaterfallShare(gameState) {
    let emojiGrid = '';

    gameState.grid.forEach((row, rowIndex) => {
        row.forEach((tile, colIndex) => {
            if (tile === '') {
                emojiGrid += '' +
                    '🟦';
            } else {
                emojiGrid += '🟩';
            }
        });
        emojiGrid += '\n';
    });

    return "Can you beat my score in Waterfall?" + "\n" +
        "Tiles Used: " + gameState.tilesUsed + "\n" +
        "Words Used: " + (10 - gameState.wordsLeft) + "\n\n" +
        emojiGrid + "\n" + Constants.BASE_URL + Games.WATERFALL.url + gameState.gameNumber

}

function getCluelessShare(gameState) {
    let shareString = "Clueless #" + gameState.gameNumber + "\n" +
        "Category: " + gameState.category + "\n" +
        "Time: " + gameState.getDisplayTime() + "\n"
    shareString += Constants.BASE_URL + Games.CLUELESS.url;
    return shareString
}

export function share(gameState) {
   let shareText = ""
    switch (gameState.gameType) {
        case Games.CROSSWORD:
            shareText = getCrosswordShare(gameState)
            break
        case Games.CHAIN:
            shareText = getChainShare(gameState)
            break
        case Games.WATERFALL:
            shareText = getWaterfallShare(gameState)
            break
        case Games.CLUELESS:
            shareText = getCluelessShare(gameState)
    }
    const shareData = {
        title: gameState.gameType.title,
        text: shareText
    }
    if (navigator.share) {
        navigator.share(shareData)
            .then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
    } else if (navigator.clipboard) {
        navigator.clipboard.writeText(shareData.text)
            .then(() => gameState.displayAlert("Copied to clipboard!"))
            .catch((error) => console.log('Error copying to clipboard', error));
    } else {
        gameState.displayAlert("Share not supported");
    }
}

function getCrosswordEmojiDiagram(gameState) {
    if (gameState.gameEnabled) return null;

    let acrossEmojiString = "Across: ";
    let downEmojiString = "Down: ";

    for (const word of gameState.wordsInOrder) {
        let correct = true;

        for (let letterIndex = 0; letterIndex < word.val.length; letterIndex++) {
            const letter = getLetterSquareFromWord(word, gameState.grid, letterIndex);
            if (letter.status !== "correct") {
                correct = false;
                break;
            }
        }

        const currentWordEmoji = correct ? "🟩" : "🟥";
        const wordRepresentation = `${word.num}${currentWordEmoji}`;

        if (word.direction !== Direction.DOWN) {
            acrossEmojiString += `${wordRepresentation} `;
        } else {
            downEmojiString += `${wordRepresentation} `;
        }
    }

    // Remove the trailing space and add a line break between across and down sections
    return `\nResults:\n${acrossEmojiString.trim()}\n\n${downEmojiString.trim()}\n\n`;
}

function getChainEmojiDiagram(gameState) {
    let emojiString = "";
    for (let word of gameState.words) {
        emojiString += word.getEmojiRepresentation();
    }
    return emojiString;
}