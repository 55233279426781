import React from "react";
import '../../css/games/Chain.css';

export const ChainGrid = ({ gameState }) => {
    return (
        <div className="chain-words">
            {gameState.words.map((word, wordIndex) => (
                <div key={wordIndex} className={`chain-word ${wordIndex === gameState.wordIndex ? 'active' : ''}`}>
                    {word.tiles.map((tile, letterIndex) => (
                        <div key={letterIndex} className={`chain-letter ${tile.status}`}>{tile.current}</div>
                    ))}
                </div>
            ))}
        </div>
    );
};