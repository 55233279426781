// src/components/Header.js
import React, { useState } from 'react';
import {Link, useLocation} from 'react-router-dom';
import './css/Header.css';
import HamburgerMenuIcon from './icons/hamburger_menu.png';
import {trackEvent, EVENTS, CATEGORIES} from "./utils/TrackingHelper";
import QuestionMarkIcon from './icons/question-mark.png';
import LegalInfoPopup from "./components/general/LegalInfoPopup";
import {Games} from "./components/shared/Constants";
import {useGlobalState} from "./contexts/GlobalStateContext";

function Header() {
    const [menuOpen, setMenuOpen] = useState(false);
    const [legalInfoOpen, setLegalInfoOpen] = useState(false);
    const { setShowLoading, setShowResults, setShowInstructions, setShowOops } = useGlobalState();
    const location = useLocation();

    const toggleMenu = () => {
        trackEvent(CATEGORIES.HEADER, EVENTS.CLICK_HAMBURGER_MENU);
        setMenuOpen(!menuOpen);
    };

    const resetGlobalState = () => {
        setShowLoading(false);
        setShowResults(false);
        setShowInstructions(false);
        setShowOops(false);
        setMenuOpen(false);
    };

    const handleLinkClick = (path) => {
        resetGlobalState();
        // Update here
        if (location.pathname === path) {
            window.location.reload();
        }
    };

    const toggleLegalInfoPopup = () => {
        setLegalInfoOpen(!legalInfoOpen)
    }

    return (
        <div className="header">
            <div className="header-left-menu">
                <div className="hamburger-menu" onClick={toggleMenu}>
                    <img src={HamburgerMenuIcon} alt="Menu" className="hamburger-icon"/>
                </div>
                <HeaderName handleLinkClick={handleLinkClick}/>
            </div>
            <HeaderGameName path={location.pathname} />
            <div className="question-mark" onClick={toggleLegalInfoPopup}>
                <img src={QuestionMarkIcon} alt="Question Menu" className="question-icon"/>
            </div>
            {legalInfoOpen && (
                <LegalInfoPopup onDismiss={toggleLegalInfoPopup}/>
            )}
            {menuOpen && (
                <div className="header-menu">
                    <div className="header-menu-container">
                        <HeaderGameIcon game={Games.HOME} handleLinkClick={handleLinkClick}/>
                        <HeaderGameIcon game={Games.CROSSWORD} handleLinkClick={handleLinkClick}/>
                        <HeaderGameIcon game={Games.CHAIN} handleLinkClick={handleLinkClick}/>
                        <HeaderGameIcon game={Games.CLUELESS} handleLinkClick={handleLinkClick}/>
                        <HeaderGameIcon game={Games.WATERFALL} handleLinkClick={handleLinkClick}/>
                    </div>
                </div>
            )}
        </div>
    );
}

function HeaderGameIcon({ game, handleLinkClick }) {
    return (
        <Link className="header-menu-link" to={game.url} onClick={() => handleLinkClick(game.url)}>
            <div className={`header-game-icon-container`}>
                <div className={`hamburger-menu-game-icon-container ${game.cssId}`}>
                    <img src={game.icon} alt={game.title} className={`hamburger-menu-game-icon ${game.cssId}`}/>
                </div>
                <div className="header-game-icon-text">{game.title}</div>
            </div>
        </Link>
    )
}

function HeaderName({handleLinkClick}) {
    return (
        <Link className="header-home-link" to="/" onClick={() => handleLinkClick(Games.HOME.url)}>
            <img src={Games.HOME.icon} alt="AI Brain Games Icon" className="header-brain-games-icon"/>
            <p className="header-title">AI BrainGames</p>
        </Link>
    )
}

function HeaderGameName({path}) {
    let game = getGameBasedOnLocation(path)
    if (!game) return (<></>)
    return (
        <div className={`header-game-name ${game.cssId}`}>{game.title}</div>
    )
}

function getGameBasedOnLocation(path) {
    if (path.includes(Games.CHAIN.url)) {
        return Games.CHAIN
    } else if (path.includes(Games.CLUELESS.url)) {
        return Games.CLUELESS
    } else if (path.includes(Games.CROSSWORD.url)) {
        return Games.CROSSWORD
    } else if (path.includes(Games.WATERFALL.url)) {
        return Games.WATERFALL
    }
    return null
}

export default Header;
