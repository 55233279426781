
const ROWS = 7;
const COLS = 7;

export function updateGridOnSuccessfulWordSubmit(gameState) {
    gameState.tilesUsed = gameState.tilesUsed + gameState.selectedTiles.length
    gameState.selectedTiles.forEach(({ row, col }) => {
        for (let r = row; r > 0; r--) {
            gameState.grid[r][col] = gameState.grid[r - 1][col];
        }
        gameState.grid[0][col] = '';
    });
    gameState.selectedTiles = []
    gameState.currentWord = ''
    gameState.wordsLeft = gameState.wordsLeft - 1
    if (gameState.wordsLeft === 0 || gameState.tilesUsed >= ROWS * COLS) {
        gameState.onGameOver(false)
    }
}