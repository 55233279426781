// src/components/general/OnScreenKeyboard.js
import React from 'react';
import '../../css/games/shared/OnScreenKeyboard.css';
import {Buttons, Games} from "./Constants";
import LeftIcon from "../../icons/left-icon.png";
import RightIcon from "../../icons/right-icon.png";
import {CluelessCurrentWord} from "../clueless/CluelessCurrentWord";
import {useGlobalState} from "../../contexts/GlobalStateContext";
import {ChainArrowButton, GameButton} from "./GameButtons";

const OnScreenKeyboard = ({}) => {
    const { gameState} = useGlobalState();

    if (!gameState || !gameState.gameType.mobileVirtualKeyboard) return (<></>);
    const gameType = gameState.gameType;
    return (
    <div className="on-screen-keyboard">
        <div className="keyboard-game-buttons-container">
            {(gameType === Games.CHAIN) && gameState.gameEnabled && (
                <>
                    <ChainArrowButton gameState={gameState}/>
                    <GameButton text="Submit" gameType={gameState.gameType} buttonType={Buttons.SUBMIT} onClick={() => gameState.onUserSubmit()} inKeyboard={true}/>
                </>
            )}
            {(gameType === Games.CHAIN) && !gameState.gameEnabled && (
                <>
                    <GameButton text="Results" gameType={gameState.gameType} buttonType={Buttons.RESULTS} onClick={() => gameState.showResults(true)} inKeyboard={true}/>
                </>
            )}
        </div>
        {gameType === Games.CLUELESS && <CluelessCurrentWord gameState={gameState} />}
        {gameType === Games.CROSSWORD && <CrosswordKeyboardSection gameState={gameState} />}
        <StandardKeyboardKeys gameState={gameState}/>
        <div className="keyboard-game-buttons-container">
            {(gameType === Games.CLUELESS && gameState.gameEnabled) && (
                <>
                    <GameButton text="Give Up" gameType={gameState.gameType} buttonType={Buttons.GIVE_UP} onClick={() => gameState.onGameOver(false)} inKeyboard={true}/>
                    <GameButton text="Submit" gameType={gameState.gameType} buttonType={Buttons.SUBMIT} onClick={() => gameState.onUserSubmit()} inKeyboard={true}/>
                </>
            )}
            {gameType === Games.CROSSWORD && (
                <>
                    <GameButton text="Give Up" gameType={gameState.gameType} buttonType={Buttons.GIVE_UP} onClick={() => gameState.onGiveUp()} inKeyboard={true}/>
                    <GameButton text="Check" gameType={gameState.gameType} buttonType={Buttons.CHECK} onClick={() => gameState.onUserCheck()} inKeyboard={true}/>
                    <GameButton text="Submit" gameType={gameState.gameType} buttonType={Buttons.SUBMIT} onClick={() => gameState.onUserSubmit()} inKeyboard={true}/>
                </>
            )}
            {((gameType === Games.CLUELESS || gameType === Games.CROSSWORD) && !gameState.gameEnabled) && (
                <>
                    <GameButton text="Results" gameType={gameState.gameType} buttonType={Buttons.RESULTS} onClick={() => gameState.showResults(true)} inKeyboard={true}/>
                </>
            )}
        </div>
    </div>
    );
};

const StandardKeyboardKeys = ({gameState}) => {
    const topRow = 'QWERTYUIOP'.split('');
    const middleRow = 'ASDFGHJKL'.split('');
    const bottomRow = 'ZXCVBNM'.split('');
    return (
        <>
            <div className="keyboard-row">
                {topRow.map(key => (
                    <button className="on-screen-keyboard-button" key={key} onClick={() => gameState.onUserInput(key)} disabled={!gameState.gameEnabled}>{key}</button>
                ))}
            </div>
            <div className="keyboard-row">
                {middleRow.map(key => (
                    <button className="on-screen-keyboard-button" key={key} onClick={() => gameState.onUserInput(key)} disabled={!gameState.gameEnabled}>{key}</button>
                ))}
            </div>
            <div className="keyboard-row">
                {bottomRow.map(key => (
                    <button className="on-screen-keyboard-button" key={key} onClick={() => gameState.onUserInput(key)} disabled={!gameState.gameEnabled}>{key}</button>
                ))}
                <button className="keyboard-backspace" onClick={() => gameState.onUserBackspace()} disabled={!gameState.gameEnabled}>⌫</button>
            </div>
        </>
    )
}

const CrosswordKeyboardSection = ({gameState}) => {
    return (
        <>
            {gameState.focusedWord && (
                <div className={"mobile-keyboard-arrow-toggle-container"}>
                    <img src={LeftIcon} alt="Left Arrow" className="mobile-keyboard-arrow-button"
                         onClick={() => gameState.onUserArrowInput("ArrowUp")}/>
                    <div className="focused-word-hint">
                        {gameState.focusedWord.num}: {gameState.focusedWord.clue}
                    </div>
                    <img src={RightIcon} alt="Right Arrow" className="mobile-keyboard-arrow-button"
                         onClick={() => gameState.onUserArrowInput("ArrowDown")}/>
                </div>
            )}
        </>
    );
};

export default OnScreenKeyboard;
