import React from 'react';
import '../../css/games/shared/Popup.css';
import {Games} from "./Constants";
import {formatTime} from "../../utils/Formatter";
import {Link} from "react-router-dom";
import PuzzleRating from "./PuzzleRating";

const Results = ({ gameState, onGenerateNewGame, onPickNewCategory }) => {
    if (!gameState) return (<></>)
    const gameType = gameState.gameType;
    const cssId = gameType.cssId
    const supportsCategories = (gameType === Games.CROSSWORD || gameType === Games.CLUELESS)
    return (
        <div className="popup">
            <div className={`popup-content ${gameType.cssId}`}>
                <button className="popup-close-button" onClick={() => gameState.showResults(false)}>X</button>
                <h2 className="popup-results-title">Results</h2>
                {gameState.isWinnable && gameState.didUserWin && <h3>You won!</h3>}
                {gameState.isWinnable && !gameState.didUserWin && <h3 className="results-lost">Sorry, you lost.<br/>Better luck next time!</h3>}
                {gameType === Games.WATERFALL && <WaterfallResultStats gameState={gameState}/>}
                {gameType === Games.CHAIN && <ChainResultStats gameState={gameState}/>}
                {gameType === Games.CLUELESS && <CluelessResultStats gameState={gameState}/>}
                {gameType === Games.CROSSWORD && <CrosswordResultStats gameState={gameState}/>}
                <PuzzleRating gameState={gameState}/>
                <button className={`popup-button share ${cssId}`} onClick={() => gameState.onShare()}>Share Game</button>
                <button className={`popup-button create-new ${cssId}`} onClick={() => onGenerateNewGame()}>Generate Another Game</button>
                {supportsCategories &&
                    <button className={`popup-button pick-new-category ${cssId}`}
                            onClick={() => onPickNewCategory()}>Change Category</button>
                }
                <PopupMoreGames currentGame={gameType}/>
            </div>
        </div>
    );
};

const WaterfallResultStats = ({gameState}) => {
    return (
        <div className="results-stats waterfall">
            <div>Tiles Used: {gameState.tilesUsed}</div>
            <div>Words Used: {(10 - gameState.wordsLeft)}</div>
        </div>
    );
}

const ChainResultStats = ({ gameState }) => {
    return (
        <div className="results-stats chain">
            <div>Time: {formatTime(gameState.timer)}</div>
            <div>Lives: {gameState.lives}</div>
        </div>
    );
}

const CluelessResultStats = ({ gameState }) => {
    return (
        <div className="results-stats clueless">
            <div>Time: {formatTime(gameState.timer)}</div>
        </div>
    );
}

const CrosswordResultStats = ({ gameState }) => {
    return (
        <div className="results-stats crossword">
            <div>Time: {formatTime(gameState.timer)}</div>
        </div>
    );
}

function PopupMoreGames({ currentGame }) {
    return (
        <div className="popup-more-games-container">
            <div className="popup-more-ai-games-title">Want more AI Brain Games?</div>
            {currentGame !== Games.CROSSWORD && <PopupGameIcon game={Games.CROSSWORD}/>}
            {currentGame !== Games.CHAIN && <PopupGameIcon game={Games.CHAIN}/>}
            {currentGame !== Games.WATERFALL && <PopupGameIcon game={Games.WATERFALL}/>}
            {currentGame !== Games.CLUELESS && <PopupGameIcon game={Games.CLUELESS}/>}
        </div>
    )
}

function PopupGameIcon({ game }) {
    return (
        <Link className="popup-game-link" to={game.url}>
            <div className={`popup-game-container ${game.cssId}`}>
                <div className={`popup-game-icon-container ${game.cssId}`}>
                    <img src={game.icon} alt={game.title} className={`popup-game-icon ${game.cssId}`}/>
                </div>
                <div className="popup-game-icon-text">{game.title}</div>
            </div>
        </Link>
    )
}

// function PuzzleRating({ gameState }) {
//     return (
//         <div className="puzzle-rating-container">
//             <p>Rate this Puzzle:</p>
//
//         </div>
//     )
// }

export default Results;
