// src/components/crossword/WaterfallGameContent.js
import React from 'react';
import { SquareUI } from './Square';
import {GameButtons} from "../shared/GameButtons";
import "../../css/games/crossword/Crossword.css"
import "../../css/games/crossword/Hints.css"
import "../../css/games/crossword/Printing.css"

export function CrosswordGameContent({ gameState, isMobile }) {
    return (
        <div className='crossword-game-content'>
            <div className="crossword-grid">
                {gameState.grid.map((row, rowIndex) => (
                    <div className="row" key={rowIndex}>
                        {row.map((square, cellIndex) => (
                            <SquareUI key={cellIndex} square={square} onClick={() => gameState.onSquareClicked(square)}
                                      isFocused={gameState.focusedSquare === square}/>
                        ))}
                    </div>
                ))}
            </div>
            {!isMobile && <Hints gameState={gameState} />}
        </div>
    );
}

function Hints({ gameState }) {
    return (
        <div className='crossword-hint-grid'>
            <GameButtons gameState={gameState}/>
            <div className="hints-section">
                <div className="across-hints-section">
                    <h2 className="hint-section-header">Across</h2>
                    {gameState.acrossWords.map((word, index) => (
                        <div
                            key={index}
                            className={`hint ${gameState.focusedWord && gameState.focusedWord.val === word.val ? 'highlight' : ''}`}
                        >
                            {word.num}: {word.clue}
                        </div>
                    ))}
                </div>
                <div className="across-hints-section">
                    <h2 className="hint-section-header">Down</h2>
                    {gameState.downWords.map((word, index) => (
                        <div
                            key={index}
                            className={`hint ${gameState.focusedWord && gameState.focusedWord.val === word.val ? 'highlight' : ''}`}
                        >
                            {word.num}: {word.clue}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
