import { CATEGORIES, EVENTS, trackEvent } from "../utils/TrackingHelper";
import { createWordsFromFetchedWords } from "../utils/ChainHelper";
import {DisplayAlert, ForceUpdate, Game, GenericGameResponse, ShowResults} from "./Game";
import {Games} from "../components/shared/Constants";

interface ChainResponse extends GenericGameResponse{
    answers: string[];
}

export class ChainGameState extends Game {

    words: any[];
    wordIndex: number;
    topIndex: number;
    bottomIndex: number;
    currentWord: string;
    focusedOnUpper: boolean;

    constructor(response: ChainResponse, displayAlert: DisplayAlert, showResults: ShowResults, forceUpdate: ForceUpdate) {
        super(forceUpdate, showResults, displayAlert, response, 5, Games.CHAIN);
        this.words = createWordsFromFetchedWords(response.answers);
        this.wordIndex = 1;
        this.topIndex = 1;
        this.bottomIndex = this.words.length - 2;
        this.currentWord = '';
        this.focusedOnUpper = true;
    }

    onUserInput(value: string) {
        this.words[this.wordIndex].handleUserInput(value);
        this.forceUpdate();
    }

    onUserBackspace() {
        this.words[this.wordIndex].handleBackSpace();
        this.forceUpdate();
    }

    onUserSubmit() {
        if (this === null || this === undefined) return;
        trackEvent(CATEGORIES.CHAIN, EVENTS.CHAIN_CLICK_SUBMIT);
        console.log(this)
        const currentWord = this.words[this.wordIndex];
        if (currentWord.isAnswerCorrect()) {
            currentWord.handleCorrectGuess();
            if (this.focusedOnUpper) {
                this.topIndex++;
                this.wordIndex = this.topIndex;
            } else {
                this.bottomIndex--;
                this.wordIndex = this.bottomIndex;
            }
            if (this.bottomIndex < this.topIndex) {
                this.onGameOver(true);
            } else {
                this.words[this.wordIndex].handleGettingFocused();
            }
        } else {
            this.words[this.wordIndex].handleIncorrectGuess();
            this.onLoseLife();
        }
        this.forceUpdate();
    }

    onUserArrowInput() {
        if (this === null) return;
        trackEvent(CATEGORIES.CHAIN, EVENTS.CHAIN_CLICK_FOCUS_ARROW_UP);
        this.words[this.wordIndex].handleLosingFocus();
        let newIndex = 1;
        if (!this.focusedOnUpper) {
            while (this.words[newIndex].isCompleted) {
                newIndex++;
            }
        } else {
            newIndex = this.words.length - 1;
            while (this.words[newIndex].isCompleted) {
                newIndex--;
            }
        }
        this.focusedOnUpper = !this.focusedOnUpper;
        this.wordIndex = newIndex;
        this.words[this.wordIndex].handleGettingFocused();
        this.forceUpdate();
    }

    override gameOverAnimation(didWin: boolean): Promise<void> {
        return new Promise<void>((resolve) => {
            const animateWord = (wordIndex: number) => {
                if (wordIndex >= this.words.length) {
                    resolve(); // All animations are complete, resolve the promise
                    return;
                }

                this.words[wordIndex].handleGameOver(didWin, 200, this.forceUpdate, () => {
                    setTimeout(() => {
                        animateWord(wordIndex + 1);
                    }, 200); // Delay between words
                });
            };

            animateWord(0); // Start the animation sequence
        });
    }

}