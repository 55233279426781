import {Direction} from "../shared/Constants";

export function handleColoringSquares(gameState) {
    for (const row of gameState.grid) {
        for (const square of row) {
            if (square.val !== null) {
                if (square.currentVal === null) {
                    square.currentVal = square.val;
                    square.status = "missing";
                } else if (square.currentVal !== square.val) {
                    square.status = "incorrect";
                    square.currentVal = square.val;
                } else {
                    square.status = "correct";
                }
            }
        }
    }
    gameState.forceUpdate();
}

export function incrementIndex(index, squareOrder) {
    let newIndex = index
    newIndex++
    if (newIndex === squareOrder.length) {
        newIndex = 0
    }
    return newIndex
}

export function decrementIndex(index, squareOrder) {
    let newIndex = index
    newIndex--
    if (newIndex < 0) {
        newIndex = squareOrder.length - 1
    }
    return newIndex
}

export function createSquareOrder(squareToWordDict, wordsInOrder, grid) {
    let counter = 0;
    let squareOrder = []
    for (const word of wordsInOrder) {
        for (let letterIndex = 0; letterIndex < word.val.length; letterIndex++) {
            squareToWordDict[counter] = word
            squareOrder.push(getLetterSquareFromWord(word, grid, letterIndex))
            counter++
        }
    }
    return squareOrder
}

export function findSquareIndex(square, squareOrder) {
    const row = square.row;
    const col = square.column;
    for (let index = 0; index < squareOrder.length; index++) {
        if (squareOrder[index].row === row && squareOrder[index].column === col) {
            return index;
        }
    }
    return -1
}

export function getLetterSquareFromWord(word, grid, letterIndex) {
    if (word.direction === Direction.ACROSS) {
        return grid[word.startRow][word.startCol + letterIndex];
    } else {
        return grid[word.startRow + letterIndex][word.startCol];
    }
}

export function sortHints(array) {
    return array.sort((a, b) => {
        if (a.startRow === b.startRow) {
            return a.startCol - b.startCol;
        } else {
            return a.startRow - b.startRow;
        }
    });
}