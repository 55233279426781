import ReactGA from "react-ga4";

export const PAGES = {
    HOME: {
        page: "/",
        title: "Home"
    },
    CROSSWORD: {
        page: "/crosswordarchitect",
        title: "Crossword Architect",
    },
    CLUELESS: {
        page: "/clueless/",
        title: "Clueless",
    },
    CHAIN: {
        page: "/chain",
        title: "The Chain"
    },
    WATERFALL: {
        page: "/waterfall",
        title: "Waterfall"
    }
}

export const CATEGORIES = {
    HOME: "Home",
    CROSSWORD: "Crossword",
    CLUELESS: "Clueless",
    CHAIN: "Chain",
    WATERFALL: "Waterfall",
    HEADER: "Header"
}

export const EVENTS = {
    //HEADER
    CLICK_HEADER_LOGO: "Click_Header_Logo",
    CLICK_HAMBURGER_MENU: "Click_Hamburger_Menu",
    CLICK_HAMBURGER_MENU_HOME: "Click_Hamburger_Menu_Home",
    CLICK_HAMBURGER_MENU_CROSSWORDS: "Click_Hamburger_Menu_Crosswords",
    CLICK_HAMBURGER_MENU_CLUELESS_MOVIES: "Click_Hamburger_Menu_Clueless_Movies",
    CLICK_HAMBURGER_MENU_CLUELESS_MUSIC: "Click_Hamburger_Menu_Clueless_Music",
    CLICK_HAMBURGER_MENU_CLUELESS: "Click_Hamburger_Menu_Clueless",
    CLICK_HAMBURGER_MENU_WATERFALL: "Click_Hamburger_Menu_Waterfall",
    CLICK_HAMBURGER_MENU_CHAIN: "Click_Hamburger_Menu_Chain",

    //HOME
    CLICK_GAME_CROSSWORD: "Click game crossword",
    CLICK_GAME_CLUELESS: "Click_Game_Clueless",
    CLICK_GAME_CLUELESS_MOVIES: "Click_Game_Movies",
    CLICK_GAME_CLUELESS_MUSIC: "Click_Game_Music",
    CLICK_GAME_CHAIN: "Click_Game_Chain",
    CLICK_GAME_WATERFALL: "Click_Game_Waterfall",


    //CROSSWORD


    //CLUELESS


    //CHAIN
    CHAIN_CLICK_SUBMIT: "Click_Submit",
    CHAIN_CLICK_FOCUS_ARROW_UP: "Click_Focus_Arrow_Up",
    CHAIN_CLICK_FOCUS_ARROW_DOWN: "Click_Focus_Arrow_Down"

    //WATERFALL

}

export function trackPage(page) {
    if (process.env.REACT_APP_ENV === 'production') {
        ReactGA.send({
            hitType: "pageview",
            page: page.page,
            title: page.title,
        });
    }
}

export function trackEvent (category, action) {
    if (process.env.REACT_APP_ENV === 'production') {
        ReactGA.event({
            category: category,
            action: action
        });
    }
}