import {formatTime, getGameSubtitle} from "../utils/Formatter";
import {share} from "../utils/SharingHelper";

export type DisplayAlert = (message: string) => void;
export type ShowResults = (show: boolean) => void;
export type ForceUpdate = () => void;

export interface GenericGameResponse {
    gameId: number;
    gameNumber: number;
    isDailyGame: boolean;
    playCount: number;
    averageRating: number;
}

export abstract class Game {

    gameId: number;
    timer: number;
    lives: number;
    gameNumber: number;
    gameEnabled: boolean;
    isDaily: boolean;
    title: string;
    gameType: any;
    subtitle: string;
    didUserWin?: boolean;
    isWinnable: boolean = true; // For games like waterfall that dont really have a "winner"
    playCount: number;
    averageRating?: number;
    isAnimating: boolean = false;
    playCounterHasBeenIncremented = false;

    forceUpdate: () => void;
    showResults: ShowResults;
    displayAlert: DisplayAlert;

    private intervalId?: NodeJS.Timeout; //For Timer

    protected constructor(
        forceUpdate: ForceUpdate,
        showResults: ShowResults,
        displayAlert: DisplayAlert,
        response: GenericGameResponse,
        lives: number = Number.MAX_VALUE,
        gameType: any,
        isWinnable = true
    ) {
        this.timer = 0;
        this.lives = lives;
        this.gameEnabled = true;
        this.gameNumber = response.gameNumber;
        this.isDaily = response.isDailyGame;
        this.gameType = gameType;
        this.title = gameType.title;
        this.subtitle = getGameSubtitle(response.gameNumber, response.isDailyGame);
        this.playCount = response.playCount;
        this.averageRating = response.averageRating;
        this.gameId = response.gameId;
        this.isWinnable = isWinnable;
        this.forceUpdate = forceUpdate;
        this.showResults = showResults;
        this.displayAlert = displayAlert;
        this.addKeyDownListener();
        this.startTimer();
    }

    startTimer() {
        this.intervalId = setInterval(() => {
            this.timer += 1;
            if (this.timer === 60) {
                this.increaseGamePlaysMetric()
            }
            this.onTimeChange?.()
            this.forceUpdate();
        }, 1000);
    }

    stopTimer() {
        if (this.intervalId !== undefined) {
            clearInterval(this.intervalId);
            this.intervalId = undefined;
        }
    }

    addKeyDownListener() {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (!this.gameEnabled) return;
            const value = e.key.toUpperCase();
            if (value.length === 1 && /^[A-Z]$/.test(value)) {
                this.onUserInput?.(value);
            } else if (e.key === 'Backspace') {
                this.onUserBackspace?.();
            } else if (e.key === 'Enter') {
                this.onUserSubmit?.();
            } else if (e.key.includes('Arrow')) {
                this.onUserArrowInput?.(e.key);
                e.preventDefault(); // Prevents scrolling with arrows
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('unload', () => window.removeEventListener('keydown', handleKeyDown));
    }

    getDisplayTime() {
        return formatTime(this.timer);
    }

    onLoseLife() {
        this.lives -= 1;
        if (this.lives === 0) {
            this.onGameOver(false);
        }
    }

    async onGameOver(didWin: boolean) {
        this.stopTimer();
        this.increaseGamePlaysMetric()
        this.gameEnabled = false;
        this.didUserWin = didWin;
        if (this.gameOverAnimation) {
            await this.gameOverAnimation(didWin);
        }

        this.showResults(true);
        this.forceUpdate();
    }

    onShare() {
        share(this)
    }

    increaseGamePlaysMetric() {
        if (!this.playCounterHasBeenIncremented) {
            // incrementPlayCounter(this.gameType.apiId, this.gameId)
            this.playCounterHasBeenIncremented = true
        }
    }

    gameOverAnimation?(didWin: boolean): Promise<void>;
    onUserInput?(value: string): void;
    onUserBackspace?(): void;
    onUserSubmit?(): void;
    onUserArrowInput?(key: string): void;
    onTimeChange?(): void;
    onCategoryChangeRight?(): void;
    onCategoryChangeLeft?(): void;

}