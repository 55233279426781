import axios from "axios";
import {API_URL} from "./GameGenerator";

export async function fetchTopCategories(gameType, gameId, rating) {
    try {
        await axios.post(`${API_URL}/rate-game/${gameType}/${gameId}/${rating}`);
    } catch (error) {
        console.error("Error fetching game:", error);
    }
}

export async function incrementPlayCounter(gameType, gameId) {
    try {
        await axios.post(`${API_URL}/increment-play/${gameType}/${gameId}`);
    } catch (error) {
        console.error("Error fetching game:", error);
    }
}