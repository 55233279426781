import {DisplayAlert, ForceUpdate, Game, GenericGameResponse, ShowResults} from "./Game";
import {createGrid} from "../components/crossword/wordPlacement";
import {Direction, Games} from "../components/shared/Constants";
import {createSquareOrder, decrementIndex, findSquareIndex, getLetterSquareFromWord, incrementIndex} from "../components/crossword/CrosswordHelper";
import {handleColoringSquares} from "../components/crossword/CrosswordHelper";

interface CrosswordResponse extends GenericGameResponse{
    category: string;
    words: any[];
    gridSize: number;
}

class Word {
    num?: number
    direction?: any = null
    startRow?: number
    startCol?: number
    clue: string
    val: string

    constructor(val: string, clue: string) {
        this.val = val;
        this.clue = clue;
        this.direction = null;
    }
}

export class CrosswordGameState extends Game {
    grid: any;
    acrossWords: any[] = []
    downWords: any[] = []
    words: Word[];
    focusedWord: Word;
    wordsInOrder: Word[];
    answerStringForPrinting: string;
    focusedSquareIndex: number;
    focusedSquare: any;
    squareOrder: any[];
    answerString: string;
    category: string;
    squareToWordDict: any = {};

    constructor(response: CrosswordResponse, displayAlert: DisplayAlert, showResults: ShowResults, forceUpdate: ForceUpdate) {
        super(forceUpdate, showResults, displayAlert, response, Number.MAX_VALUE, Games.CROSSWORD);
        console.log(response)
        this.focusedSquareIndex = 0;
        this.words = response.words
            .map(item => new Word(item.answer.toUpperCase(), item.clue))
            .sort((a, b) => b.val.length - a.val.length)
            .filter(word => word.val.length <= response.gridSize);
        this.grid = createGrid(response.gridSize, this.words, this.acrossWords, this.downWords);
        this.wordsInOrder = [...this.acrossWords, ...this.downWords]
        this.focusedWord = this.wordsInOrder[0]
        this.answerStringForPrinting = this.createAnswerStringForPrinting(this.wordsInOrder)
        this.squareOrder = createSquareOrder(this.squareToWordDict, this.wordsInOrder, this.grid)
        this.category = response.category;
        this.answerString = "";
        const firstSquare = getLetterSquareFromWord(this.wordsInOrder[0], this.grid, 0);
        this.setFocusedSquare(firstSquare, 0);
        firstSquare.status = 'active';
    }

    onUserInput(value: string) {
        this.focusedSquare.currentVal = value.toUpperCase();
        this.updateFocusedSquare(Direction.RIGHT)
        this.forceUpdate();
    }

    onUserBackspace() {
        if (this.focusedSquare && this.focusedSquare.currentVal !== null) {
            this.focusedSquare.currentVal = null;
        }
        this.updateFocusedSquare(Direction.LEFT)
        this.forceUpdate();
    }

    onUserSubmit() {
        if (this.gameEnabled) {
            const allFilled = this.wordsInOrder.every(word =>
                word.val.split('').every((_, letterIndex) => {
                    const letter = getLetterSquareFromWord(word, this.grid, letterIndex);
                    return letter.currentVal !== null;
                })
            );

            if (!allFilled) {
                this.displayAlert("Please fill all squares before submitting.")
                return;
            }

            let allCorrect = true;
            for (const word of this.wordsInOrder) {
                for (let letterIndex = 0; letterIndex < word.val.length; letterIndex++) {
                    const letter = getLetterSquareFromWord(word, this.grid, letterIndex);
                    if (letter.currentVal !== null && letter.currentVal !== letter.val) {
                        // Wrong letter
                        letter.status = "incorrect";
                        allCorrect = false;
                    } else {
                        letter.status = "correct";
                    }
                }
            }

            this.forceUpdate();

            if (allCorrect) {
                this.onGameOver(true)
            } else {
                this.displayAlert("There are some incorrect letters. Please fix them and try again.")
            }
        }
    }

    onUserCheck() {
        if (this.gameEnabled) {
            for (const word of this.wordsInOrder) {
                for (let letterIndex = 0; letterIndex < word.val.length; letterIndex++) {
                    const letter = getLetterSquareFromWord(word, this.grid, letterIndex);
                    if (letter.currentVal !== null && letter.currentVal !== letter.val) {
                        letter.status = "incorrect";
                    } else if (letter.currentVal && letter.currentVal === letter.val) {
                        letter.status = "correct";
                    }
                }
            }
            this.forceUpdate();
        }
    }

    onUserArrowInput(arrow: string) {
        if (arrow === "ArrowLeft") {
            this.updateFocusedSquare(Direction.LEFT)
        } else if (arrow === "ArrowRight") {
            this.updateFocusedSquare(Direction.RIGHT)
        } else if (arrow === "ArrowDown") {
            this.updateFocusedSquare(Direction.DOWN)
        } else if (arrow === "ArrowUp") {
            this.updateFocusedSquare(Direction.UP)
        }
    }

    createAnswerStringForPrinting(wordsInOrder: any[]) {
        let answersString = ""
        for (let word of wordsInOrder) {
            answersString = answersString + word.num + ": " + word.val + "  ";
        }
        return answersString
    }

    onFocusChanged(newFocusedWord: Word) {
        if (!this.focusedWord) {
            this.focusedWord = newFocusedWord;
        } else {
            if (!newFocusedWord) return;
            if (newFocusedWord.val !== this.focusedWord.val) {
                this.focusedWord = newFocusedWord;
            }
        }
    }

    updateFocusedSquare(direction: any) {
        this.focusedSquare.status = "neutral"
        let index = this.focusedSquareIndex
        let focusedWord = this.squareToWordDict[index]
        if (direction === Direction.RIGHT) {
            index = incrementIndex(index, this.squareOrder)
        } else if (direction === Direction.DOWN) {
            while (direction === Direction.DOWN && this.squareToWordDict[index] === focusedWord) {
                index = incrementIndex(index, this.squareOrder)
            }
        } else if (direction === Direction.LEFT) {
            index = decrementIndex(index, this.squareOrder)
        } else if (direction === Direction.UP) {
            while (direction === Direction.UP && this.squareToWordDict[index] === focusedWord) {
                index = decrementIndex(index, this.squareOrder)
            }
            index = index - (this.squareToWordDict[index].val.length - 1)
        }
        let newSquare = this.squareOrder[index]
        newSquare.status = "active"
        this.setFocusedSquare(newSquare, index)
    }

    setFocusedSquare(focusedSquare: any, newIndex: number) {
        this.focusedSquareIndex = newIndex
        let focusedWord = this.squareToWordDict[newIndex]
        this.focusedSquare = focusedSquare
        this.onFocusChanged(focusedWord)
        this.forceUpdate();
    }

    onSquareClicked(clickedSquare: any) {
        if (!this.gameEnabled) return;
        this.focusedSquare.status = 'neutral';
        let index = findSquareIndex(clickedSquare, this.squareOrder);
        this.squareOrder[index].status = "active"
        this.setFocusedSquare(clickedSquare, index);
    };

    override gameOverAnimation(didWin: boolean): Promise<void> {
        return new Promise<void>((resolve) => {
            handleColoringSquares(this);
            resolve()
        });
    }

}