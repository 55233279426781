import React, { useState } from 'react';
import '../../css/CategorySetup.css';
import { IoIosArrowForward } from "react-icons/io";

function CategorySetup({ popularCategories, fetchGame, gameType }) {
    const [inputCategory, setInputCategory] = useState("");
    const dateOptions = { day: 'numeric', month: 'short', timeZone: 'America/New_York' };
    const easternTime = new Date().toLocaleDateString("en-US", dateOptions);

    return (
        <div className={`category-setup-screen ${gameType.cssId}`}>
            <div className="category-setup-container">
                <h2 className="category-setup-title">{gameType.title}</h2>
                <div className="category-setup-daily">
                    <button
                        onClick={() => fetchGame(null, null, true)}
                        className={`category-setup-daily-button ${gameType.cssId}`}>Daily Game - {easternTime}</button>
                </div>
                <div className="create-category-container">
                    <h3>Category</h3>
                    <div className={`create-category-input-container ${gameType.cssId}`}>
                        <input
                            type="text"
                            value={inputCategory}
                            onChange={(e) => setInputCategory(e.target.value)}
                            className={`create-category-input ${gameType.cssId}`}
                        />
                        <IoIosArrowForward onClick={() => fetchGame(null, inputCategory, false)} className={`category-setup-submit-button ${gameType.cssId}`}/>
                    </div>

                    <h3>Popular</h3>
                    <div className="category-setup-popular-category-container">
                        {
                            popularCategories?.data?.length > 0 ? (
                                popularCategories.data.map(({name, plays}) => (
                                    <button
                                        onClick={() => fetchGame(null, name, false)}
                                        className={`category-setup-popular-category ${gameType.cssId}`}
                                        key={name}
                                    >
                                        {name}
                                    </button>
                                ))
                            ) : (
                                <p>No popular categories available.</p>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CategorySetup;