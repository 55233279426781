import React from 'react';
import '../../css/games/shared/Instructions.css';
import ChainArrowRight from "../../icons/chain-arrow-right.png";

const WaterfallInstructions = ({}) => {
    return (
        <div className="instructions-waterfall-content">
            <h1 className="instructions-header-how-to-play waterfall">How to Play</h1>
            <p>A random grid of letters is generated each day</p>
            <p>Using the bottom row of tiles, find words to create</p>
            <div>
                <div className="instructions-waterfall-example-container">
                    <div className="instructions-waterfall-example-content">
                        <div className="instructions-waterfall-example-row">
                            <div className="instructions-waterfall-example-letter-disabled">R</div>
                            <div className="instructions-waterfall-example-letter-disabled">H</div>
                            <div className="instructions-waterfall-example-letter-disabled">A</div>
                            <div className="instructions-waterfall-example-letter-disabled">N</div>
                            <div className="instructions-waterfall-example-letter-disabled">T</div>
                        </div>
                        <div className="instructions-waterfall-example-row">
                            <div className="instructions-waterfall-example-letter-disabled">P</div>
                            <div className="instructions-waterfall-example-letter-disabled">G</div>
                            <div className="instructions-waterfall-example-letter-disabled">S</div>
                            <div className="instructions-waterfall-example-letter-disabled">A</div>
                            <div className="instructions-waterfall-example-letter-disabled">B</div>
                        </div>
                        <div className="instructions-waterfall-example-divider-line"></div>
                        <div className="instructions-waterfall-example-row">
                            <div className="instructions-waterfall-example-letter">T</div>
                            <div className="instructions-waterfall-example-letter">E</div>
                            <div className="instructions-waterfall-example-letter">N</div>
                            <div className="instructions-waterfall-example-letter">C</div>
                            <div className="instructions-waterfall-example-letter">A</div>
                        </div>
                    </div>
                    <img src={ChainArrowRight} alt="Right Arrow"
                         className="waterfall-arrow-example-instructions"/>
                    <div className="instructions-waterfall-example-word-row">
                        <div className="instructions-waterfall-example-word-letter">C</div>
                        <div className="instructions-waterfall-example-word-letter">A</div>
                        <div className="instructions-waterfall-example-word-letter">T</div>
                    </div>
                </div>
            </div>
            <p className="instructions-waterfall-example-text">When a tile is used, the tiles above it will fall
                down</p>
            <div className="instructions-waterfall-example-content">
                <div className="instructions-waterfall-example-row">
                    <div className="instructions-waterfall-example-letter-empty"/>
                    <div className="instructions-waterfall-example-letter-disabled">H</div>
                    <div className="instructions-waterfall-example-letter-disabled">A</div>
                    <div className="instructions-waterfall-example-letter-empty"/>
                    <div className="instructions-waterfall-example-letter-empty"/>
                </div>
                <div className="instructions-waterfall-example-row">
                    <div className="instructions-waterfall-example-letter-disabled">R</div>
                    <div className="instructions-waterfall-example-letter-disabled">G</div>
                    <div className="instructions-waterfall-example-letter-disabled">S</div>
                    <div className="instructions-waterfall-example-letter-disabled">N</div>
                    <div className="instructions-waterfall-example-letter-disabled">T</div>
                </div>
                <div className="instructions-waterfall-example-divider-line"></div>
                <div className="instructions-waterfall-example-row">
                    <div className="instructions-waterfall-example-letter">P</div>
                    <div className="instructions-waterfall-example-letter">E</div>
                    <div className="instructions-waterfall-example-letter">N</div>
                    <div className="instructions-waterfall-example-letter">A</div>
                    <div className="instructions-waterfall-example-letter">B</div>
                </div>
            </div>
            <p>You have 10 words to use as many tiles as you can!</p>
        </div>
    );
};

export default WaterfallInstructions;
