import {EVENTS, PAGES} from "../../utils/TrackingHelper";
import CrosswordIcon from '../../icons/Crossword_Icon.png';
import CluelessIcon from '../../icons/Clueless_Movies_Icon.png';
import WaterfallIcon from '../../icons/Waterfall_Icon.png';
import ChainIcon from '../../icons/ChainIconv2.png';
import BrainGamesHeadIcon from '../../icons/AIBrainGamesHead.png';

export const Constants = {
    BASE_URL: "https://www.aibraingames.io"
}

export const DisplayStats = {
    TIME: "Time",
    CATEGORY: "Category",
    LIVES: "Lives",
    WORDS_LEFT: "Words_Left",
    TILES_USED: "Tiles_Used"
}

export const Buttons = {
    SUBMIT: 'submit',
    GIVE_UP: 'give-up',
    CLEAR: 'clear',
    RESULTS: 'results',
    CHECK: 'check',
    SHARE: 'share',
    PRINT: 'print',
    CHAIN_ARROWS: 'chain-arrows',
}

export const Games = {
    CROSSWORD: {
        title: 'Crossword Architect',
        subtitle: 'Create crosswords about ANYTHING using the power of AI',
        description: 'A classic with a modern twist. Pick any topic and difficulty, and you\'ll get your very own crossword with the power of AI!',
        icon: CrosswordIcon,
        url: '/crosswordarchitect',
        cssId: 'crossword',
        shareText: 'Share Your Puzzle',
        apiId: 'crossword',
        trackingId: 'Crossword',
        pageTracking: PAGES.CROSSWORD,
        trackingHomeClick: EVENTS.CLICK_GAME_CROSSWORD,
        trackingHamburgerClick: EVENTS.CLICK_HAMBURGER_MENU_CROSSWORDS,
        displayStats: [DisplayStats.CATEGORY, DisplayStats.TIME],
        mobileVirtualKeyboard: true,
        buttons: [Buttons.SHARE, Buttons.PRINT, Buttons.GIVE_UP, Buttons.CHECK, Buttons.SUBMIT]
    },
    CHAIN: {
        title: 'The Chain',
        subtitle: 'AI has created a chain of words. Can you figure out how the first word connects to the last?',
        description: 'AI has created a secret chain of words. Can you connect the first word to the last word?',
        icon: ChainIcon,
        url: '/chain',
        cssId: 'chain',
        shareText: 'Share Your Game',
        wonMessage: 'You Won!',
        lostMessage: 'Results',
        apiId: 'chain',
        trackingId: 'Chain',
        pageTracking: PAGES.CHAIN,
        trackingHomeClick: EVENTS.CLICK_GAME_CHAIN,
        trackingHamburgerClick: EVENTS.CLICK_HAMBURGER_MENU_CHAIN,
        displayStats: [DisplayStats.LIVES, DisplayStats.TIME],
        mobileVirtualKeyboard: true,
        buttons: [Buttons.SUBMIT]
    },
    WATERFALL: {
        title: 'Waterfall',
        subtitle: 'AI has created a chain of words. Can you figure out how the first word connects to the last?',
        description: 'A cascade of letters has appeared. Create words while keeping in mind what tiles will replace them. How many tiles can you use?',
        icon: WaterfallIcon,
        url: '/waterfall',
        cssId: 'waterfall',
        shareText: 'Share Your Game',
        wonMessage: 'You Won!',
        lostMessage: 'Results',
        apiId: 'waterfall',
        trackingId: 'Waterfall',
        pageTracking: PAGES.WATERFALL,
        trackingHomeClick: EVENTS.CLICK_GAME_WATERFALL,
        trackingHamburgerClick: EVENTS.CLICK_HAMBURGER_MENU_WATERFALL,
        displayStats: [DisplayStats.WORDS_LEFT, DisplayStats.TILES_USED],
        mobileVirtualKeyboard: false,
        buttons: [Buttons.GIVE_UP, Buttons.CLEAR, Buttons.SUBMIT]
    },
    CLUELESS: {
        title: 'Clueless',
        subtitle: 'Given a category, AI has chosen a secret word. How many clues will you need to figure out the secret word?',
        description: 'Give AI a category, and it will pick a secret word. As the clock ticks, more clues are revealed. How quickly can you guess it?',
        icon: CluelessIcon,
        url: "/clueless/",
        cssId: 'clueless',
        apiId: 'clueless',
        trackingId: 'Clueless',
        pageTracking: PAGES.CLUELESS,
        trackingHomeClick: EVENTS.CLICK_GAME_CLUELESS,
        trackingHamburgerClick: EVENTS.CLICK_HAMBURGER_MENU_CLUELESS,
        displayStats: [DisplayStats.CATEGORY, DisplayStats.TIME],
        mobileVirtualKeyboard: true,
        buttons: [Buttons.GIVE_UP, Buttons.SUBMIT]
    },
    HOME: {
        title: "Home",
        icon: BrainGamesHeadIcon,
        url: "/",
        cssId: 'home',
        pageTracking: PAGES.HOME,
    }
};

export const Direction = {
    RIGHT: 'RIGHT',
    LEFT: 'LEFT',
    UP: 'UP',
    DOWN: 'DOWN'
};