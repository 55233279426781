import React from 'react';
import '../css/LoadingV2.css';
import LoadingGif from '../icons/aibraingames_loading.gif';

function LoadingV2() {
    return (
        <div className="loading-v2">
            <img className="loading-v2-image" src={LoadingGif} alt="Loading..."/>
            <div className="loading-v2-text">Generating Your Game...</div>
        </div>
    );
}

export default LoadingV2;
