import React from 'react';
import '../../css/games/shared/Instructions.css';
import LeftIcon from "../../icons/left-icon.png";
import RightIcon from "../../icons/right-icon.png";

const CrosswordInstructions = ({}) => {
    return (
        <div className="instructions-chain-content">
            <h1 className="instructions-header-title crossword">Crossword Architect</h1>
            <h1 className="instructions-header-how-to-play clueless">How to Play</h1>
            <p>Given any category, AI will create your very own crossword!</p>
            <p>You can create a puzzle on <b>anything</b></p>
            <div className='instructions-category-example-container'>
                <div className={`category-setup-popular-category crossword`}>Star Wars</div>
                <div className={`category-setup-popular-category crossword`}>Classic Rock</div>
                <div className={`category-setup-popular-category crossword`}>Literature</div>
            </div>
            <div className={`category-setup-popular-category crossword`}>Movies DiCaprio didn't win an Oscar for</div>
            <br/>
            <p>Don't like the puzzle you got?<br/>Use the category arrows to create another</p>
            <div className="category-display">
                <img src={LeftIcon} alt="Left Arrow" className={`category-arrow`}/>
                <h2 className="category-heading">Harry Potter #5</h2>
                <img src={RightIcon} alt="Right Arrow" className="category-arrow"/>
            </div>
            <br/>
            <p>Get creative and see what new puzzles you can create!</p>
        </div>
    );
};

export default CrosswordInstructions;
