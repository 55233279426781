import React, {createContext, useState, useContext, useReducer, ReactNode, useEffect} from 'react';
import { GameGenerator } from "../api/GameGenerator";
import {useLocation, useNavigate} from 'react-router-dom';
import {Game} from "../Models/Game";

interface GlobalFunctions {
    setShowLoading: (loading: boolean) => void;
    setShowInstructions: (show: boolean, gameType: any | null) => void;
    onDisplayAlert: (message: string) => void;
    setGameState: (game: Game | null) => void;
    setShowKeyboard: (showKeyboard: boolean) => void;
    setGameType: (gameType: any | null) => void;
    forceUpdate: () => void;
    setShowResults: (show: boolean) => void;
    navigate: (path: string) => void;
    setShowOops: (show: boolean, onOopsRetry: any | null) => void;
}

interface GlobalState extends GlobalFunctions {
    showAlert: boolean;
    showLoading: boolean;
    gameState: Game | null;
    showKeyboard: boolean;
    showInstructions: boolean;
    gameType: any | null;
    alertText: string;
    forceUpdate: () => void;
    showResults: boolean;
    gameGenerator: GameGenerator;
    isMobile: boolean;
    showOops: boolean;
    onOopsRetry: (() => void) | null;
}

const GlobalStateContext = createContext<GlobalState | undefined>(undefined);

export const useGlobalState = (): GlobalState => {
    const context = useContext(GlobalStateContext);
    if (!context) {
        throw new Error("useGlobalState must be used within a GlobalStateProvider");
    }
    return context;
};

export const useForceUpdate = () => {
    return useReducer(() => ({}), {})[1] as () => void;
};

interface GlobalStateProviderProps {
    children: ReactNode;
}

export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ children }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false);
    const [showOops, setShowOops] = useState(false);
    const [onOopsRetry, setOnOopsRetry] = useState<(() => void) | null>(null);
    const [gameType, setGameType] = useState<any | null>(null);
    const [gameState, setGameState] = useState<Game | null>(null);
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [alertText, setAlertText] = useState('');
    const forceUpdate = useForceUpdate();
    const [showResults, setShowResults] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100);
    const navigate = useNavigate();
    const location = useLocation();

    const onDisplayAlert = (text: string) => {
        setAlertText(text);
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
            setAlertText('');
        }, 2000);
    };

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 1100);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (!location.pathname.includes(gameType?.url)) {
            setGameState(null);
        }
    }, [location]);

    const handleSetShowInstructions = (show: boolean, gameType: any | null = null) => {
        setShowInstructions(show);
        setGameType(gameType);
    };

    const handleSetShowOops = (show: boolean, onRetry: any | null = null) => {
        setShowOops(show);
        setOnOopsRetry(() => onRetry);
    };

    const globalFunctions: GlobalFunctions = {
        setShowLoading,
        setShowInstructions: handleSetShowInstructions,
        setShowOops: handleSetShowOops,
        setShowKeyboard,
        onDisplayAlert,
        setGameState,
        setGameType,
        forceUpdate,
        setShowResults,
        navigate
    };

    const gameGenerator = new GameGenerator(globalFunctions);

    return (
        <GlobalStateContext.Provider value={{
            ...globalFunctions,
            gameState,
            showKeyboard,
            showAlert,
            showLoading,
            showInstructions,
            showOops,
            onOopsRetry,
            gameType,
            alertText,
            showResults,
            gameGenerator,
            isMobile
        }}>
            {children}
        </GlobalStateContext.Provider>
    );
};