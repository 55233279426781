import React from "react";
import '../../css/games/Clueless.css';

export const CluelessGameContent = ({ gameState, isMobile }) => {
    return (
        <>
            <div className="clueless-content">
                {gameState?.clues?.map((clue, index) => (
                    <div key={index} className={`clueless-clue ${index < gameState.visibleClues ? "visible" : ""}`}>
                        <div className="clueless-clue-number">Clue {index + 1}</div>
                        <div className="clueless-clue-text">{clue}</div>
                        <div
                            className="clueless-clue-time">{index < gameState.visibleClues ? '' : `Unlocked at: ${Math.floor(gameState.clueTimes[index] / 60)}:${('0' + (gameState.clueTimes[index] % 60)).slice(-2)}`}</div>
                    </div>
                ))}
            </div>
            {!isMobile && (
                <div className="clueless-guess-inputs">
                    {gameState.userGuess.map((letter, index) => (
                        <div key={index}
                             className={`clueless-guess-tile ${index === gameState.currentIndex ? 'active' : ''}`}>
                            {letter}
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};