import React, { createContext, ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const NavigationContext = createContext<ReturnType<typeof useNavigate> | undefined>(undefined);

interface NavigationProviderProps {
    children: ReactNode;
}

export const NavigationProvider: React.FC<NavigationProviderProps> = ({ children }) => {
    const navigate = useNavigate();
    return (
        <NavigationContext.Provider value={navigate}>
            {children}
        </NavigationContext.Provider>
    );
};