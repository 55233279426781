import React, { useState, useEffect } from 'react';

class Square {
    constructor(val, row, column) {
        this.val = val;
        this.row = row;
        this.column = column;
        this.num = null;
        this.currentVal = null;
        this.status = "neutral";
    }
}

function SquareUI({ square, onClick, isFocused }) {
    const [inputValue, setInputValue] = useState(square.currentVal || "");

    useEffect(() => {
        setInputValue(square.currentVal || ""); // Sync state with square prop
    }, [square]);

    useEffect(() => {
        if (isFocused) {
            square.status = "active";
        } else if (square.status === "active") {
            square.status = "neutral";
        }
    }, [isFocused, square]);

    useEffect(() => {
        setInputValue(square.currentVal || ""); // Sync state with square prop
    }, [square.currentVal]);

    const handleOnClick = () => {
        if (square.val !== null) {
            onClick(square);
        }
    };

    const getStatusClass = (status) => {
        switch (status) {
            case "incorrect":
                return "yellow";
            case "missing":
                return "red";
            case "correct":
                return "green";
            case "active":
                return "blue";
            case "secondary":
                return "secondary"
            default:
                return "";
        }
    };

    return (
        <div className={`square ${getStatusClass(square.status)} ${square.val === null ? 'black' : ''}`} onClick={handleOnClick}>
            {square.num && <div className="square-num">{square.num}</div>}
            <span className="square-text">{inputValue}</span>
        </div>
    );
}

export { Square, SquareUI };
