import {ChainTileStatus} from "../../utils/ChainHelper";

export class ChainTile {
    constructor(correct, current, status) {
        this.correct = correct;
        this.current = current;
        this.status = status;
    }

    getEmojiColor() {
        switch (this.status) {
            case ChainTileStatus.CORRECT:
            case ChainTileStatus.VICTORY:
                return "🟩"
            case ChainTileStatus.GIVEN:
                return "🟦"
            case ChainTileStatus.REVEALED:
            case ChainTileStatus.INCORRECT:
            case ChainTileStatus.DISABLED:
            case '':
            default:
                return "🟥"
        }
    }
}