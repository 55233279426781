import React from "react";
import '../../css/games/shared/TitleComponents.css';
import {DisplayStats} from "./Constants";

export const TitleAndSubtitle = ({ gameState }) => {
    const cssId = gameState.gameType.cssId;
    return (
        <div className={`game-title-container ${cssId}`}>
            {!gameState.gameType.displayStats.includes(DisplayStats.CATEGORY) && gameState.subtitle !== "" && <h2 className={`game-subtitle ${cssId}`}>{gameState.subtitle}</h2>}
        </div>
    )
}
