import React from "react";
import '../../css/games/Waterfall.css';

export const WaterfallGameContent = ({ gameState }) => {
    const ROWS = 7;

    const handleTileClick = (row, col) => {
        if (row !== (ROWS - 1) || !gameState.gameEnabled) return;

        const tile = { row, col, letter: gameState.grid[row][col] };
        const alreadySelected = gameState.selectedTiles.some(t => t.row === row && t.col === col);

        if (alreadySelected) {
            gameState.setSelectedTiles(gameState.selectedTiles.filter(t => !(t.row === row && t.col === col)));
            gameState.setCurrentWord(gameState.currentWord.replace(tile.letter, ''));
        } else {
            gameState.setSelectedTiles([...gameState.selectedTiles, tile]);
            gameState.setCurrentWord(gameState.currentWord + tile.letter);
        }
    };

    return (
        <>
            <div className="waterfall-grid">
                {gameState.grid.map((row, rowIndex) => (
                    <div className={`waterfall-row ${rowIndex === (ROWS - 1) ? 'bottom' : ''}`} key={rowIndex}>
                        {row.map((tile, colIndex) => (
                            <div
                                className={`waterfall-tile ${tile === '' ? 'empty' : ''} ${gameState.selectedTiles.some(t => t.row === rowIndex && t.col === colIndex) ? 'selected' : ''}`}
                                key={colIndex}
                                onClick={() => handleTileClick(rowIndex, colIndex)}>
                                {tile}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <div className="waterfall-word-input">
                <p className="current-word">{gameState.currentWord || <>&nbsp;</>}</p>
            </div>
        </>
    );
};