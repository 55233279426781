import {DisplayAlert, ForceUpdate, Game, GenericGameResponse, ShowResults} from "./Game";
import {Games} from "../components/shared/Constants";

interface CluelessResponse extends GenericGameResponse{
    grid: any[];
    gameNumber: number;
    isDailyGame: boolean;
    category: string;
    answer: string;
    clues: string[];
}

export class CluelessGameState extends Game {

    gameNumber: number;
    category: string;
    didUserWin: boolean = false;
    answer: string;
    clues: string[];
    currentWord: string = '';
    currentIndex: number = 0;
    isSubmitEnabled: boolean = false;
    userGuess: string[];
    clueTimes: any;
    visibleClues: number = 1;

    constructor(response: CluelessResponse, displayAlert: DisplayAlert, showResults: ShowResults, forceUpdate: ForceUpdate) {
        super(forceUpdate, showResults, displayAlert, response, Number.MAX_VALUE, Games.CLUELESS)
        this.gameNumber = response.gameNumber;
        this.gameEnabled = true;
        this.clues = response.clues;
        this.category = response.category;
        this.answer = response.answer.toUpperCase().replace(/\s/g, "");
        console.log(this.answer)
        this.forceUpdate = forceUpdate;
        this.clueTimes = response?.clues?.map((_, index) => (index) * 10)
        this.userGuess = Array(this.answer.length).fill(' ');
    }

    onUserBackspace() {
        if (this.currentIndex > 0) {
            this.userGuess[this.currentIndex - 1] = ''
            this.currentIndex = this.currentIndex - 1
        }
        this.isSubmitEnabled = false;
        this.forceUpdate()
    }

    onUserInput(value: string) {
        if (this.currentIndex < this.answer.length) {
            this.userGuess[this.currentIndex] = value;
            this.currentIndex = this.currentIndex + 1;
        }
        this.isSubmitEnabled = this.currentIndex === this.answer.length;
        this.forceUpdate()
    }

    onUserSubmit() {
        if (!this.isSubmitEnabled) return;
        const guess = this.userGuess.join('');
        if (guess === this.answer) {
            this.onGameOver(true);
        } else {
            this.displayAlert("Incorrect. Try again")
            this.clearInput()
        }
        this.forceUpdate()
    }

    clearInput() {
        for (let index= 0; index < this.userGuess.length; index++) {
            this.userGuess[index] = ''
        }
        this.currentIndex = 0
    }

    onUserArrowInput = (): void => {};

    onTimeChange(){
        if (this.clueTimes.includes(this.timer)) {
            this.visibleClues ++
        }
    }

    override gameOverAnimation(didWin: boolean): Promise<void> {
        return new Promise<void>((resolve) => {
            this.currentIndex = -1;
            this.forceUpdate();
            let index = 0;

            const revealAnswer = () => {
                for (let index= 0; index < this.answer.length; index++) {
                    this.userGuess[index] = this.answer[index]
                }
                this.forceUpdate();
                setTimeout(resolve, 1000)
            }

            const revealNextClue = () => {
                if (this.visibleClues < this.clues.length) {
                    this.visibleClues++;
                    this.forceUpdate(); // Ensure the UI updates after changing visibleClues
                    index++;
                    setTimeout(revealNextClue, 500);
                } else {
                    if (didWin) {
                        resolve()
                    } else {
                        revealAnswer()
                    }
                }
            };

            revealNextClue();
        });
    }

}