import { Direction } from '../shared/Constants';
import {Square} from "./Square";
import {sortHints} from "./CrosswordHelper";

export function createGrid(gridSize, words, acrossWords, downWords) {
    const grid = Array.from({ length: gridSize }, (_, row) =>
        Array.from({ length: gridSize }, (_, column) => new Square(null, row, column))
    );
    populateGrid(words, grid, acrossWords, downWords);

    sortHints(acrossWords);
    sortHints(downWords);

    return grid;
}

function populateGrid(words, grid, acrossWords, downWords) {
    let wordMap = new Map();
    words.forEach(word => wordMap.set(word.val, false));

    let activeTiles = new Set();
    placeFirstWord(words[0], grid, acrossWords, downWords, activeTiles)
    wordMap[words[0].val] = true;

    let foundBranch = true;
    while (foundBranch) {
        foundBranch = findNewPotentialBranch(words, grid, acrossWords, downWords, activeTiles, wordMap)
    }
    addNumbers(acrossWords, downWords, grid)
}

function placeFirstWord(word, grid, acrossWords, downWords, activeTiles) {
    const middleIndex = Math.floor(grid.length / 2);
    const wordLength = word.val.length;
    const middleLetterIndex = Math.floor(wordLength / 2);

    const row = middleIndex;
    const col = middleIndex - middleLetterIndex;
    placeWordAt(word, grid, row, col, Direction.ACROSS, acrossWords, downWords, activeTiles)
}

function canPlaceWordAt(word, grid, row, column, direction) {
    if (isWordOutOfBounds(word, grid, row, column, direction, grid.length)) return false;
    for (let i = 0; i < word.val.length; i++) {
        const letter = word.val[i]
        if (direction === Direction.ACROSS) {
            if (!isValidSquare(grid, row, (column + i), letter, direction, i, word.val.length)) {
                return false;
            }
        } else if (direction === Direction.DOWN) {
            if (!isValidSquare(grid, (row + i), column, letter, direction, i, word.val.length)) {
                return false
            }
        }
    }
    return true;
}

function isWordOutOfBounds(word, grid, row, column, direction) {
    if (row < 0 || column < 0) return true;
    if (direction === Direction.ACROSS) {
        return ((word.val.length + column) >= grid.length)
    } else {
        return ((word.val.length + row) >= grid.length)
    }
}

function isValidSquare(grid, row, column, letter, direction, index, length) {   
    if (grid[row][column].val !== null && grid[row][column].val !== letter) {
        return false
    }
    var isConnectionSquare = (grid[row][column].val === letter)
    var validNeighbors = hasValidNeighbors(grid, row, column, letter, direction, index, length, isConnectionSquare);
    return validNeighbors;
}

function hasValidNeighbors(grid, row, column, letter, direction, index, length, isConnectionSquare) {
    if (direction === Direction.ACROSS) {
        if (!isConnectionSquare) {
            if (!isSquareEmpty(grid, row - 1, column)) return false;
            if (!isSquareEmpty(grid, row + 1, column)) return false;
        }
        if (index === 0) {
            if (!isSquareEmpty(grid, row, column - 1)) return false;    
        }
        if (index === length - 1) {
            if (!isSquareEmpty(grid, row, column + 1)) return false;    
        }
    } else {
        if (index === 0) {
            if (!isSquareEmpty(grid, row - 1, column)) return false;
        }
        if (index === length - 1) {
            if (!isSquareEmpty(grid, row + 1, column)) return false;
        }
        if (!isConnectionSquare) {
            if (!isSquareEmpty(grid, row, column - 1)) return false;
            if (!isSquareEmpty(grid, row, column + 1)) return false;
        }
    }  
    return true
}

function isSquareEmpty(grid, row, column) {
    if (row < 0 || column < 0 || row >= grid.length || column >= grid[0].length) return true;
    return grid[row][column].val === null;
}

function findNewPotentialBranch(words, grid, acrossWords, downWords, activeTiles, wordMap) {
    activeTiles.forEach(tile => {
        const letterToMatch = tile.val;
        for (var wordIndex = 0; wordIndex < words.length; wordIndex++) {
            const word = words[wordIndex];
            if (wordMap[word.val] == true) continue;
            const wordVal = word.val
            for (var letterIndex = 0; letterIndex < wordVal.length; letterIndex++) {
                const letter = wordVal[letterIndex];
                if (letterToMatch === letter) {
                    const row = tile.row
                    const col = tile.column
                    if (canPlaceWordAt(word, grid, row, col - letterIndex, Direction.ACROSS)) {
                        placeWordAt(word, grid, row, col - letterIndex, Direction.ACROSS, acrossWords, downWords, activeTiles);
                        wordMap[word.val] = true
                        return true;
                    } else if (canPlaceWordAt(word, grid, row - letterIndex, col, Direction.DOWN)) {
                        placeWordAt(word, grid, row - letterIndex, col, Direction.DOWN, acrossWords, downWords, activeTiles);
                        wordMap[word.val] = true
                        return true;
                    }
                }
            }
        }
    });
    return false;
}

function placeWordAt(word, grid, startRow, startCol, direction, acrossWords, downWords, activeTiles) {
    word.direction = direction;

    if (direction === Direction.ACROSS) {
        acrossWords.push(word)
        for (let i = 0; i < word.val.length; i++) {
            grid[startRow][startCol + i].val = word.val[i];
            activeTiles.add(grid[startRow][startCol + i]);
            if (i === 0) {
                word.startRow = startRow
                word.startCol = startCol + i
            }
        }
    } else if (direction === Direction.DOWN) {
        downWords.push(word)
        for (let i = 0; i < word.val.length; i++) {
            grid[startRow + i][startCol].val = word.val[i];
            activeTiles.add(grid[startRow + i][startCol]);
            if (i === 0) {
                word.startRow = startRow + i
                word.startCol = startCol
            }
        }
    }
}

function addNumbers(acrossWords, downWords, grid) {
    var counter = 1;

    for (const aWord of acrossWords) {
        aWord.num = counter;
        grid[aWord.startRow][aWord.startCol].num = counter;
        counter++;
    }

    for (const dWord of downWords) {
        if (grid[dWord.startRow][dWord.startCol].num === null) {
            dWord.num = counter;
            grid[dWord.startRow][dWord.startCol].num = counter;
            counter++;
        } else {
            dWord.num = grid[dWord.startRow][dWord.startCol].num;
        }
    }
}