import {ChainTileStatus} from "../../utils/ChainHelper";

export class ChainWord {
    constructor(tiles, correctWord, isCompleted, isEditable) {
        this.tiles = tiles;
        this.correctWord = correctWord;
        this.currentIndex = 1;
        this.startingIndex = 1;
        this.isCompleted = isCompleted;
        this.isEditable = isEditable;
        if (!isEditable && !isCompleted) {
            for (let tile of tiles) {
                if (tile.status !== ChainTileStatus.GIVEN) {
                    tile.status = ChainTileStatus.DISABLED
                }
            }
        }
    }

    isAnswerCorrect() {
        for (let tile of this.tiles) {
            if (tile.current !== tile.correct) {
                return false;
            }
        }
        return true;
    }

    handleCorrectGuess() {
        for (let tile of this.tiles) {
            if (tile.status === '' || tile.status === ChainTileStatus.HIGHLIGHTED) {
                tile.status =  ChainTileStatus.CORRECT
            }
        }
        this.isEditable = false
        this.isCompleted = true
    }

    handleIncorrectGuess() {
        if (this.startingIndex !== this.correctWord.length - 1) {
            let currentStart = this.tiles[this.startingIndex]
            currentStart.status = ChainTileStatus.REVEALED
            currentStart.current = currentStart.correct
            this.startingIndex++
            this.currentIndex = this.startingIndex
        }
        for (let index = 0; index < this.tiles.length; index++) {
            let tile = this.tiles[index]
            if (tile.status !== ChainTileStatus.REVEALED && tile.status !== ChainTileStatus.GIVEN) {
                tile.current = ''
                tile.status = ''
                if (index === this.startingIndex) {
                    tile.status = ChainTileStatus.HIGHLIGHTED
                }
            }
        }
        console.log(this)
    }

    handleUserInput(value) {
        if (value.length === 1 && /^[A-Z]$/.test(value)) {
            let currentTile = this.tiles[this.currentIndex]
            currentTile.current = value
            currentTile.status = ''
            if (this.currentIndex !== this.tiles.length - 1) {
                this.currentIndex++
                this.tiles[this.currentIndex].status = ChainTileStatus.HIGHLIGHTED
            }
        }
    }

    handleBackSpace() {
        if (this.currentIndex !== this.tiles.length && this.tiles[this.currentIndex].current !== '') {
            this.tiles[this.currentIndex].current = ''
            this.tiles[this.currentIndex].status = ChainTileStatus.HIGHLIGHTED
        } else {
            let prevTile = this.tiles[this.currentIndex - 1]
            if (prevTile.status !== ChainTileStatus.REVEALED && prevTile.status !== ChainTileStatus.GIVEN) {
                this.tiles[this.currentIndex].status = ''
                this.currentIndex--
                this.tiles[this.currentIndex].status = ChainTileStatus.HIGHLIGHTED
                this.tiles[this.currentIndex].current = ''
            }
        }
    }

    handleLosingFocus() {
        this.currentIndex = this.startingIndex
        for (let index = this.startingIndex; index < this.tiles.length; index++) {
            this.tiles[index].status = ''
        }
    }

    handleGettingFocused() {
        this.currentIndex = this.startingIndex
        for (let index = this.startingIndex + 1; index < this.tiles.length; index++) {
            this.tiles[index].status = ''
        }
        this.tiles[this.startingIndex].status = ChainTileStatus.HIGHLIGHTED
    }

    getEmojiRepresentation() {
        let emojiString = ""
        for (let tile of this.tiles) {
            emojiString += tile.getEmojiColor()
        }
        emojiString += "\n"
        return emojiString
    }

    handleGameOver(isVictory, delay = 200, forceUpdate, onComplete = () => {}) {
        let animationDelay = 0;
        if (isVictory) {
            setTimeout(() => {
                this.tiles.forEach((tile, index) => {
                    tile.status = ChainTileStatus.VICTORY;
                });
                forceUpdate();
                onComplete();
            });
        } else {
            this.tiles.forEach((tile, index) => {
                if (tile.status !== ChainTileStatus.GIVEN && tile.status !== ChainTileStatus.CORRECT && tile.status !== ChainTileStatus.REVEALED) {
                    setTimeout(() => {
                        tile.status = ChainTileStatus.INCORRECT;
                        tile.current = tile.correct;
                        forceUpdate();
                        if (index === this.tiles.length - 1) {
                            onComplete();
                        }
                    }, animationDelay);
                    animationDelay += delay;
                }
            });
        }
        if (animationDelay === 0) {
            onComplete();
        }
    }
}