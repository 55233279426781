import React from "react";
import '../../css/games/shared/Buttons.css';
import {Buttons, Games} from "./Constants";
import ChainUpArrow from "../../icons/chain-up-arrow.png";
import ChainDownArrow from "../../icons/chain-down-arrow.png";
import {MdIosShare} from "react-icons/md";

export const GameButtons = ({ gameState }) => {
    let gameButtons = gameState.gameType.buttons;
    let gameType = gameState.gameType;
    return (
        <div className={`game-buttons-container ${gameState.gameType.cssId}`}>
            {gameState.gameEnabled && (
                <>
                    {gameType === Games.CHAIN && <ChainArrowButton gameState={gameState}/>}
                    {/*{gameButtons.includes(Buttons.SHARE) && <MdIosShare className='share-icon' onClick={() => gameState.onShare()}/>}*/}
                    {/*{gameButtons.includes(Buttons.PRINT) && <GameButton text="Print" gameType={gameType} onClick={() => window.print()} buttonType={Buttons.PRINT}/>}*/}
                    {gameButtons.includes(Buttons.GIVE_UP) && <GameButton text="Give Up" gameType={gameType} onClick={() => gameState.onGameOver(false)} buttonType={Buttons.GIVE_UP}/>}
                    {gameButtons.includes(Buttons.CLEAR) && <GameButton text="Clear" gameType={gameType} onClick={() => gameState.clearClicked()} buttonType={Buttons.CLEAR}/>}
                    {gameButtons.includes(Buttons.CHECK) && <GameButton text="Check" gameType={gameType} onClick={() => gameState.onUserCheck()} buttonType={Buttons.CHECK}/>}
                    {gameButtons.includes(Buttons.SUBMIT) && <GameButton text="Submit" gameType={gameType} onClick={() => gameState.onUserSubmit()} buttonType={Buttons.SUBMIT}/>}
                </>
            )}
            {!gameState.gameEnabled && (
                <>
                    <GameButton text="Results" gameType={gameType} onClick={() => gameState.showResults(true)} buttonType={Buttons.RESULTS}/>
                </>
            )}
        </div>
    )
}

export const ChainArrowButton = ({gameState}) => {
    return (
        <>
            {!gameState.focusedOnUpper &&
                <img src={ChainUpArrow} alt="Up Arrow" className="chain-arrow-btn" onClick={() => gameState.onUserArrowInput()}/>}
            {gameState.focusedOnUpper &&
                <img src={ChainDownArrow} alt="Down Arrow" className="chain-arrow-btn" onClick={() => gameState.onUserArrowInput()}/>}
        </>
    )
}

export const GameButton = ({text, onClick, buttonType, gameType, inKeyboard = false}) => {
    let keyboardId = inKeyboard ? 'keyboard' : ''
    return (
        <button className={`game-button ${buttonType} ${keyboardId} ${gameType.cssId}`} onClick={onClick}>{text}</button>
    )
}

const GetIconForButton = ({buttonType, gameState}) => {
    switch (buttonType) {
        case Buttons.SHARE:
            return <MdIosShare className='share-icon' onClick={() => gameState.onShare()}/>

    }
}