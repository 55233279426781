import React from 'react';
import '../../css/games/shared/Instructions.css';
import ChainDownArrow from "../../icons/chain-down-arrow.png";
import ChainArrowRight from "../../icons/chain-arrow-right.png";

const ChainInstructions = ({}) => {
    return (
        <div className="instructions-chain-content">
            <h1 className="instructions-header-how-to-play">How to Play</h1>
            <p>AI has created a secret chain of words</p>
            <p>Any words that touch should make sense together</p>
            <div className="instructions-chain-example">
                <div className="instructions-chain-example-board">
                    <div className="instructions-chain-example-word">
                        <div className="instructions-chain-example-letter">S</div>
                        <div className="instructions-chain-example-letter">U</div>
                        <div className="instructions-chain-example-letter">N</div>
                    </div>
                    <div className="instructions-chain-example-word">
                        <div className="instructions-chain-example-letter">L</div>
                        <div className="instructions-chain-example-letter">I</div>
                        <div className="instructions-chain-example-letter">G</div>
                        <div className="instructions-chain-example-letter">H</div>
                        <div className="instructions-chain-example-letter">T</div>
                    </div>
                    <div className="instructions-chain-example-word">
                        <div className="instructions-chain-example-letter">H</div>
                        <div className="instructions-chain-example-letter">O</div>
                        <div className="instructions-chain-example-letter">U</div>
                        <div className="instructions-chain-example-letter">S</div>
                        <div className="instructions-chain-example-letter">E</div>
                    </div>
                    <div className="instructions-chain-example-word">
                        <div className="instructions-chain-example-letter">P</div>
                        <div className="instructions-chain-example-letter">A</div>
                        <div className="instructions-chain-example-letter">Y</div>
                        <div className="instructions-chain-example-letter">M</div>
                        <div className="instructions-chain-example-letter">E</div>
                        <div className="instructions-chain-example-letter">N</div>
                        <div className="instructions-chain-example-letter">T</div>
                    </div>
                </div>
                <div className="instructions-chain-example-explanations">
                    <div className="instructions-chain-example-explanation-line">
                        <img src={ChainArrowRight} alt="Right Arrow"
                             className="chain-arrow-example-instructions"/>
                        <div className="instructions-chain-example-explanation-word">Sunlight</div>
                    </div>
                    <div className="instructions-chain-example-explanation-line">
                        <img src={ChainArrowRight} alt="Right Arrow"
                             className="chain-arrow-example-instructions"/>
                        <div className="instructions-chain-example-explanation-word">Lighthouse</div>
                    </div>
                    <div className="instructions-chain-example-explanation-line">
                        <img src={ChainArrowRight} alt="Right Arrow"
                             className="chain-arrow-example-instructions"/>
                        <div className="instructions-chain-example-explanation-word">House payment</div>
                    </div>
                </div>
            </div>
            <div className="instructions-chain-arrow-container">
                <p>You can work from the top or bottom. Use</p>
                <img src={ChainDownArrow} alt="Down Arrow" className="chain-arrow-btn-instructions"/>
                <p>to toggle</p>
            </div>

            <p>Can you connect the words and complete the chain?</p>
        </div>
    );
};

export default ChainInstructions;
