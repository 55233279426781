import React, { useState } from 'react';
import { FaRegStar, FaStar } from "react-icons/fa";
import {fetchTopCategories} from "../../api/GameReports";

function PuzzleRating({ gameState }) {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);

    const handleRatingClick = (rating) => {
        setRating(rating);
        fetchTopCategories(gameState.gameType.apiId, gameState.gameId, rating)
    };

    return (
        <div className="puzzle-rating-container">
            <div className="puzzle-rating-text-and-input">
                <p>Rate Your Puzzle:</p>
                <div className="rating-stars">
                    {[1, 2, 3, 4, 5].map((starValue) => (
                        <span
                            key={starValue}
                            onClick={() => handleRatingClick(starValue)}
                            onMouseEnter={() => setHover(starValue)}
                            onMouseLeave={() => setHover(0)}
                            className="rating-star"
                        >
                            {hover >= starValue || rating >= starValue ? <FaStar /> : <FaRegStar />}
                        </span>
                    ))}
                </div>
            </div>
            <p>(It helps us generate better games!)</p>
        </div>
    );
}

export default PuzzleRating;