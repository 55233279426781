import React from "react";

export const CluelessCurrentWord = ({ gameState }) => {
    return (
        <div className="clueless-guess-inputs">
            {gameState.userGuess.map((letter, index) => (
                <div key={index} className={`clueless-guess-tile ${index === gameState.currentIndex ? 'active' : ''}`}>
                    {letter}
                </div>
            ))}
        </div>
    )
}