import React from 'react';
import '../../css/general/OopsPage.css';
import MainIcon from "../../icons/AIBrainGamesHead.png";

const OopsPage = ({ onRetry }) => {
    return (
        <div className="oops-page-container">
            <img src={MainIcon} alt="AI Brain Games Icon" className="oops-page-image"/>
            <div className="oops-page-text">Uh oh. Something didn't work!</div>
            <button className="oops-page-retry" onClick={onRetry}>Retry</button>
        </div>
    );
};

export default OopsPage;