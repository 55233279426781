// src/components/crossword/Popup.js
import React from 'react';
import '../../css/games/shared/Instructions.css';
import {Games} from "../shared/Constants";
import ChainInstructions from "./ChainInstructions";
import WaterfallInstructions from "./WaterfallInstructions";
import {useGlobalState} from "../../contexts/GlobalStateContext";
import CluelessInstructions from "./CluelessInstructions";
import CrosswordInstructions from "./CrosswordInstructions";

const Instructions = ({}) => {
    const { setShowInstructions, gameType } = useGlobalState();
    const onDismiss = () => {
        setShowInstructions(false);
    }
    return (
        <div className="instructions-popup">
            <div className={`instructions-content ${gameType.cssId}`}>
                <button className="instructions-close" onClick={onDismiss}>X</button>
                {gameType === Games.CHAIN && (
                    <ChainInstructions />
                )}
                {gameType === Games.WATERFALL && (
                    <WaterfallInstructions />
                )}
                {gameType === Games.CROSSWORD && (
                    <CrosswordInstructions />
                )}
                {gameType === Games.CLUELESS && (
                    <CluelessInstructions />
                )}
                <button className={`instructions-play-button ${gameType.cssId}`} onClick={onDismiss}>Play</button>
            </div>
        </div>
    );
};

export default Instructions;
