import { updateGridOnSuccessfulWordSubmit } from "../utils/WaterfallHelper";
import { validateWord } from "../utils/wordValidation";
import {DisplayAlert, ForceUpdate, Game, GenericGameResponse, ShowResults} from "./Game";
import {Games} from "../components/shared/Constants";

interface WaterfallResponse extends GenericGameResponse{
    grid: any[];
}

export class WaterfallGameState extends Game {
    wordsLeft: number;
    tilesUsed: number;
    grid: any[];
    selectedTiles: any[];
    currentWord: string;

    constructor(
        response: WaterfallResponse,
        displayAlert: DisplayAlert,
        showResults: ShowResults,
        forceUpdate: ForceUpdate
    ) {
        super(forceUpdate, showResults, displayAlert, response, 10, Games.WATERFALL, false)
        this.wordsLeft = 10;
        this.tilesUsed = 0;
        this.grid = response.grid;
        this.selectedTiles = [];
        this.currentWord = '';
    }

    override onUserSubmit() {
        this.submitClicked()
    }

    async submitClicked() {
        if (this.currentWord.length > 0) {
            const isValid = await validateWord(this.currentWord);
            if (isValid) {
                updateGridOnSuccessfulWordSubmit(this);
            } else {
                this.displayAlert("Invalid word. Please try again.");
            }
            this.forceUpdate();
        }
    }

    clearClicked() {
        this.selectedTiles = [];
        this.currentWord = '';
        this.forceUpdate();
    }

    setSelectedTiles(selectedTiles: any[]) { // Replace `any` with the specific type if known
        this.selectedTiles = selectedTiles;
        this.forceUpdate();
    }

    setCurrentWord(word: string) {
        this.currentWord = word;
        this.forceUpdate();
    }

}