import React, {useEffect, useRef, useState} from 'react';
import { trackPage } from "../utils/TrackingHelper";
import { Games } from "../components/shared/Constants";
import {useParams} from "react-router-dom";
import CategorySetup from "../components/shared/CategorySetup";
import {useGlobalState} from "../contexts/GlobalStateContext";
import Results from "../components/shared/Results";
import {TitleAndSubtitle} from "../components/shared/TitleAndSubtitle";
import {StatsAndInfo} from "../components/shared/StatsAndInfo";
import {GameButtons} from "../components/shared/GameButtons";
import {CluelessGameContent} from "../components/clueless/CluelessGameContent";
import {ChainGrid} from "../components/chain/ChainGrid";
import {WaterfallGameContent} from "../components/waterfall/WaterfallGameContent";
import {CrosswordGameContent} from "../components/crossword/CrosswordGameContent";

export const GenericGamePage = ({gameType}) => {
    trackPage(gameType.pageTracking);
    const { gameGenerator, isMobile, showResults, setShowInstructions } = useGlobalState();
    const { category, gameNumber } = useParams();
    const gameStateRef = useRef(null);
    const [showSetup, setShowSetup] = useState(false);
    const [popularCategories, setPopularCategories] = useState([]);

    const fetchGame = async (id, category, isDaily) => {
        setShowSetup(false)
        gameStateRef.current = await gameGenerator.fetchGameHandler(gameType, id, category, isDaily);
    };

    const fetchCategories = async() => {
        const categories = await gameGenerator.fetchTopCategories(gameType.apiId);
        if (categories) { setPopularCategories(categories) }
    }

    useEffect(() => {
        if (gameNumber !== undefined) {
            fetchGame(gameNumber, category, false);
        } else {
            if (gameType === Games.CROSSWORD || gameType === Games.CLUELESS) {
                setShowSetup(true)
                setShowInstructions(true, gameType)
                fetchCategories()
            } else {
                fetchGame(null, null, true)
            }
        }
    }, []);

    const onPickNewCategory = () => {
        setShowInstructions(true, gameType)
        setShowSetup(true)
        fetchCategories()
    }

    const onGenerateNewGame = () => {
        let id = gameNumber ? gameNumber + 1 : null;
        fetchGame(id, category, false);
    };

    const onNewGameInCategory = (increase) => {
        if (increase) {
            fetchGame((gameState.gameNumber + 1), gameState.category, false);
        } else {
            fetchGame((gameState.gameNumber - 1), gameState.category, false);
        }
    };

    const gameState = gameStateRef.current;

    return (
        <>
            {gameState && !showSetup && (
                <>
                    <TitleAndSubtitle gameState={gameState} />
                    <StatsAndInfo gameState={gameState} onNewGameInCategory={onNewGameInCategory} />
                    <GameContent gameState={gameState} isMobile={isMobile} />
                    {(gameType !== Games.CROSSWORD && (!isMobile || gameType === Games.WATERFALL)) && <GameButtons gameState={gameState} />}
                    {showResults && (<Results gameState={gameState} onGenerateNewGame={onGenerateNewGame} onPickNewCategory={onPickNewCategory}/>)}
                </>
            )}
            {showSetup && (<CategorySetup popularCategories={popularCategories} fetchGame={fetchGame} gameType={gameType}/>)}
        </>
    );
};

const GameContent = ({ gameState, isMobile }) => {
    return (
        <>
            {gameState.gameType === Games.CLUELESS && <CluelessGameContent gameState={gameState} isMobile={isMobile}/>}
            {gameState.gameType === Games.CHAIN && <ChainGrid gameState={gameState} />}
            {gameState.gameType === Games.WATERFALL && <WaterfallGameContent gameState={gameState} />}
            {gameState.gameType === Games.CROSSWORD && <CrosswordGameContent gameState={gameState}  isMobile={isMobile}/>}
        </>
    )
}