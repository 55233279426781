import React from 'react';
import '../../css/games/shared/Instructions.css';
import ChainDownArrow from "../../icons/chain-down-arrow.png";
import ChainArrowRight from "../../icons/chain-arrow-right.png";

const CluelessInstructions = ({}) => {
    return (
        <div className="instructions-chain-content">
            <h1 className="instructions-header-title clueless">Clueless</h1>
            <h1 className="instructions-header-how-to-play clueless">How to Play</h1>
            <p>Given any category, AI will pick a relevant secret word</p>
            <div className="instructions-clueless-example">
                <div className="instructions-clueless-category-example-row">
                    <div className="instructions-clueless-example-category">Harry Potter</div>
                    <div className="instructions-clueless-example-text">Category</div>
                </div>
                <img src={ChainArrowRight} alt="Right Arrow" className="chain-arrow-example-instructions"/>
                <div className="instructions-clueless-category-example-row">
                    <div className="instructions-clueless-example-category">Dumbledore</div>
                    <div className="instructions-clueless-example-text">Secret Word</div>
                </div>
            </div>
            <p>You will then be given a list of clues to help you solve the word.</p>
            <div className="instructions-clueless-clue">
                <div className="instructions-clueless-clue-number">Clue 1</div>
                <div className="instructions-clueless-clue-text">This person is a wizard</div>
            </div>
            <div className="instructions-clueless-clue">
                <div className="instructions-clueless-clue-number">Clue 2</div>
                <div className="instructions-clueless-clue-text">He is known for being wise</div>
            </div>
            <div className="instructions-clueless-clue">
                <div className="instructions-clueless-clue-number">Clue 3</div>
                <div className="instructions-clueless-clue-text-hidden">This person is a wizard</div>
                <div className="instructions-clueless-clue-time">Unlocked at: 0:20</div>
            </div>
            <p>As the time increases, more clues are revealed</p>
            <p>How many clues do you need before you guess the secret word?</p>
        </div>
    );
};

export default CluelessInstructions;
