import axios from "axios";
import { Games } from "../components/shared/Constants";
import { ChainGameState } from "../Models/ChainGameState";
import {WaterfallGameState} from "../Models/WaterfallGameState";
import {CluelessGameState} from "../Models/CluelessGameState";
import {CrosswordGameState} from "../Models/CrosswordGameState";

const BASE_API_URL = "https://ai-brain-games.onrender.com"
const DEV_API_URL = "http://localhost:5000"

export const API_URL = (process.env.REACT_APP_ENV === 'production') ? BASE_API_URL : DEV_API_URL;

export class GameGenerator {
    constructor(globalFunctions) {
        this.setShowLoading = globalFunctions.setShowLoading;
        this.setGameType = globalFunctions.setGameType;
        this.forceUpdate = globalFunctions.forceUpdate;
        this.setShowOops = globalFunctions.setShowOops;
        this.onDisplayAlert = globalFunctions.onDisplayAlert;
        this.setShowResults = globalFunctions.setShowResults;
        this.setGameState = globalFunctions.setGameState;
        this.setShowInstructions = globalFunctions.setShowInstructions;
        this.setShowKeyboard = globalFunctions.setShowKeyboard;
        this.navigate = globalFunctions.navigate;
    }

    async fetchTopCategories(game) {
        this.setShowLoading(true)
        let response;
        try {
            response = await axios.post(`${API_URL}/popular-categories/${game}`);
            this.setShowLoading(false)
            return response
        } catch (error) {
            console.error("Error fetching game:", error);
            this.setShowLoading(false);
            this.setShowOops(true, () => this.fetchTopCategories(game));
        }
    }

    async fetchGameHandler(gameType, gameNumber, category, isDaily) {
        console.log("Fetching new game: " + gameType + " - " + gameNumber + " - " + category + " - " + isDaily);
        this.setShowResults(false);
        this.setShowOops(false);
        this.setShowLoading(true);
        this.setGameType(gameType);

        try {
            let response = await axios.post(`${API_URL}/generate-game/${gameType.apiId}`, {category, gameNumber, isDaily});
            console.log(`Results: ${response}`);
            this.setShowLoading(false);
            if (gameType !== Games.CROSSWORD && gameType !== Games.CLUELESS) {
                this.setShowInstructions(true, gameType);
            }
            this.navigate(this.getURLForNewGame(gameType, response.data));
            let gameState = this.getGameStateFromResponse(gameType, response.data);
            this.setGameState(gameState)
            this.forceUpdate()
            return gameState
        } catch (error) {
            console.error("Error fetching game:", error);
            this.setShowLoading(false);
            this.setShowOops(true, () => this.fetchGameHandler(gameType, gameNumber, category, isDaily));

        }
    }

    getGameStateFromResponse(gameType, responseData) {
        switch (gameType) {
            case Games.WATERFALL:
                return new WaterfallGameState(responseData, this.onDisplayAlert, this.setShowResults, this.forceUpdate);
            case Games.CHAIN:
                return new ChainGameState(responseData, this.onDisplayAlert, this.setShowResults, this.forceUpdate);
            case Games.CLUELESS:
                return new CluelessGameState(responseData, this.onDisplayAlert, this.setShowResults, this.forceUpdate);
            case Games.CROSSWORD:
                return new CrosswordGameState(responseData, this.onDisplayAlert, this.setShowResults, this.forceUpdate);
                break;
            default:
                throw new Error("Invalid game type");
        }
    }

    getURLForNewGame(gameType, response) {
        console.log(response)
        let dailyOrCustom = response.isDailyGame ? 'daily' : 'custom'
        switch (gameType) {
            case Games.WATERFALL:
                return `/waterfall/${dailyOrCustom}/${response.gameNumber}`;
            case Games.CHAIN:
                return `/chain/${dailyOrCustom}/${response.gameNumber}`
            case Games.CLUELESS:
                return `/clueless/${response.category}/${response.gameNumber}`;
            case Games.CROSSWORD:
                return `/crosswordarchitect/${response.category}/${response.gameNumber}`;
        }
    }
}